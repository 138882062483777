import { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import projectsService from './../services/projects'
import projectUserRelsService from './../services/projectUserRels'
import documentsService from './../services/documents'
import mediasService from './../services/medias'
import supportTicketsService from './../services/supportTickets'
import supportTicketMessagesService from './../services/supportTicketMessages'
import usersService from './../services/users'
import utilsService from './../services/utils'

/**
 * mount/unmount React pattern
 * @param {function} onMounted 
 * @param {function} onUnmounted 
 * @returns 
 */
export function useMounted(onMounted, onUnmounted) {
  let isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      onMounted && onMounted()
    }
    return () => {
      isMounted.current = false
      onUnmounted && onUnmounted()
    }
  }, [onMounted, onUnmounted])
  return useCallback(() => isMounted.current, [])
}

/**
 * Display message on UI from everyWhere
 * @returns 
 */
export function useMessage () {
  const { enqueueSnackbar } = useSnackbar()
  return {
    info: (message) => {
      enqueueSnackbar(message)
    },
    success: (message) => {
      enqueueSnackbar(message, {variant: 'success'})
    },
    warning: (message) => {
      enqueueSnackbar(message, {variant: 'warning'})
    },
    error: (error) => {
      enqueueSnackbar(utilsService.getErrorMessage(error), {variant: 'error'})
    }
  }
}

/**
 * Toggle boolean value
 * @param {boolean} initialState 
 * @returns array[booleanValue, toggleBooleanValue()]
 */
export function useToggle (initialState = false) {
  // Initialize the state
  const [state, setState] = useState(initialState)
  
  // Define and memorize toggler function in case we pass down the component,
  // This function change the boolean value to it's opposite value
  const toggle = useCallback(() => setState(state => !state), [])
  
  return [state, toggle]
}

export function useListSelect () {

  const [ selected, setSelected ] = useState([])

  // Lorsqu'un ensemble ou un item est sélectionné
  const onSelect = (item) => {
    let newValue = [...selected]
    if (Array.isArray(item)) {
      // si la nouvelle valeur est un tableau on écrase tout
      newValue = item
    } else {
      // sinon c'est que c'est un item unique
      const found = newValue && newValue.filter((selected) => selected === item)
      if (found.length > 0) {
        // si le nouvel item est déjà dans la liste, on le retire
        newValue = newValue.filter((selected) => selected !== item)
      } else {
        // sinon on l'ajoute
        newValue.push(item)
      }
    }
    // on met à jour la sélection
    setSelected(newValue)
  }

  return [ selected, onSelect ]
}

/**
 * Manage FormData
 * @param {object} initialData 
 * @param {object} presetData 
 * @param {boolean} existingKeysConstraint default true - permet de ne charger les valeurs de preset que si la clé existe dans initialData
 * @returns object
 */
export function useFormData (options = {initialData: {}, presetData: {}, mergeFormDataFilter: null, existingKeysConstraint: true}) {

  // Initialize the data
  let initialAvailableData = {...options.initialData}
  if (!options.existingKeysConstraint) {
    initialAvailableData = {...options.initialAvailableData, ...options.presetData}
  } else {
    for (const key in options.presetData) {
      if (key in initialAvailableData) {
        initialAvailableData[key] = options.mergeFormDataFilter ? options.mergeFormDataFilter(key, options.presetData[key]) : options.presetData[key]
      } else {
        console.warn(`la clé ${key} n'existe pas dans les données initiales du formulaire`);
      }
    }
  }
  const [data, setData] = useState(initialAvailableData)
  
  /**
   * Remplace les data avec les nouvelles
   * @param {object} newData
   */
  const setFormData = useCallback((newData) => setData((data) => {
    if (!options.existingKeysConstraint) {
      return newData
    }
    let availableData = {}
    for (const key in newData) {
      if (key in data) {
        availableData[key] = newData[key]
      } else {
        console.warn(`la clé ${key} n'existe pas dans les données initiales du formulaire`);
      }
    }
    return availableData
  }), [options.existingKeysConstraint])

  /**
   * Merge les data avec les nouvelles
   * @param {object} newData
   */
  const mergeFormData = useCallback((newData) => setData((data) => {
    if (!options.existingKeysConstraint) {
      return {...data, ...newData}
    }
    let availableData = {}
    for (const key in newData) {
      if (key in data) {
        availableData[key] = options.mergeFormDataFilter ? options.mergeFormDataFilter(key, newData[key]) : newData[key]
      } else {
        console.warn(`la clé ${key} n'existe pas dans les données initiales du formulaire`);
      }
    }
    return {...data, ...availableData}
  }), [options])
  
  return {formData: data, setFormData, mergeFormData}
}

/**
 * Manage Edit Entity
 * @param {object} options {id:number, initialData:object, presetData:object, mergeFormDataFilter:func, queryKey:string, queryStaleTime:number, get:func, create:func, update:func, delete:func, onSaved:func, onDeleted:func, onError:func}
 * @returns object
 */
export function useEditEntity (options = {}) {

  const queryClient = useQueryClient()

  const {formData, mergeFormData, setFormData} = useFormData({
    initialData: options?.initialData,
    presetData: options?.presetData,
    mergeFormDataFilter: options?.mergeFormDataFilter,
    existingKeysConstraint: options?.existingKeysConstraint || true
  })

  const isNew = useRef(options.id ? false : true)

  const queryKeys = [options.queryKey, options.id || 'new']

  // Chargement
  const { isLoading, isFetching, isError, data: entity } = useQuery(queryKeys, () => {
    if (options.id) {
      return options?.get(options.id).then(([item, notices]) => {
        notices && notices.length > 0 && options?.onError && options.onError(notices)
        return item
      })
    }
    return false
  }, {
    staleTime: options.queryStaleTime || 0, // IMPORTANT : on garde toujours un staleTime à 0 car en édition on doit avoir des données fraiches (de plus sinon cela génère un bug de rafraichissement des données dans react-query)
    onSuccess: (item) => item && mergeFormData(item),
    onError: (error) => options?.onError && options.onError(error)
  })

  // Après chaque mutation
  const afterMutation = (notices) => {
    notices && notices.length > 0 && options?.onError && options.onError(notices)
    // propagation pour permettre d'invalider les queries souhaitées
    options?.afterMutation && options.afterMutation({notices, queryClient, queryKeys})
  }

  // Mutation (create/update)
  const { isLoading: isMutating, mutate } = useMutation((data) => {
    return options.id ? options?.update(options.id, data) : options?.create(data)
  }, {
    onSuccess: ([id, notices]) => {
      afterMutation(notices)
      // propagation
      options?.onSaved && options.onSaved(id, isNew.current)
    },
    onError: (error) => options?.onError && options.onError(error)
  })

  // Mutation (delete)
  const { isLoading: isMutatingDel, mutate: mutateDel } = useMutation((data) => {
    return options.id && options?.delete(options.id)
  }, {
    onSuccess: ([id, notices]) => {
      afterMutation(notices)
      // propagation
      options?.onDeleted && options.onDeleted(id)
    },
    onError: (error) => options?.onError && options.onError(error)
  })
  
  return {isNew: isNew.current, entity, formData, mergeFormData, setFormData, isLoading, isFetching, isError, isMutating, isMutatingDel, mutate, mutateDel}
}

/**
 * Manage Edit Medias's Entity
 * @param {object} options {query:object, queryKeys:array, getAll:func, upload:func, delete:func, onSuccess:func, onError:func}
 * @returns object
 */
export function useEditMediasEntity (options = {}) {

  // Chargement des médias
  const { isLoading, isFetching, isError, data: medias, refetch } = useQuery(options.queryKeys, () => {
    return options.getAll(options.query).then(([items, notices]) => {
      notices && notices.length > 0 && options.onError(notices)
      options.onGetAll && options.onGetAll(items)
      return items
    })
  }, {
    staleTime: 0, // IMPORTANT : on garde toujours un staleTime à 0 car en édition on doit avoir des données fraiches (de plus sinon cela génère un bug de rafraichissement des données dans react-query)
    onError: (error) => options.onError && options.onError(error),
  })

  // Mutation (upload/update/delete) - Enregistrement du nouvel ensemble de médias
  const { isLoading: isMutating, mutate } = useMutation((data) => {
    let promises = []
    const { medias: newMedias, args } = data
    // suppression des medias qui ont été retirés
    if (medias) {
      medias.forEach(media => {
        // eslint-disable-next-line
        if (!newMedias || newMedias.length === 0 || newMedias.filter((newMedia) => media.ID == newMedia.ID).length < 1) {
          // suppression du media
          promises.push(options.delete(media.ID))
        }
      })
    }
    // création des nouveau médias (ceux ayant la clé 'upload')
    if (newMedias && newMedias.length > 0) {
      newMedias.forEach(newMedia => {
        if (newMedia.upload) {
          promises.push(options.upload({...{file: newMedia.file}, ...args} ))
        }
      })
    }
    return Promise.all(promises).then(() => {
      return {newMedias, args}
    })
  }, {
    onSuccess: (data) => options.onSuccess && options.onSuccess(data),
    onError: (error) => options.onError && options.onError(error)
  })

  return { mutate, isMutating, isLoading, isFetching, isError, refetch, medias }
}

/**
 * Calculate permissions for loggedIn user
 * @returns object
 */
 export function usePermissions (options = {}) {

  const {
    userLoggedIn,
  } = useSelector(state => ({
    userLoggedIn: state.auth.user,
  }))

  // Chargement des rôles dans des projets
  const query = userLoggedIn?.ID ? {id_user: userLoggedIn.ID} : undefined
  const {
    isLoading,
    isFetching,
    isError,
    refetch,
    data: projectUserRels
  } = useQuery(['project-user-rels', query], async () => {
    return projectUserRelsService.getAll(query).then(([items]) => items)
  }, { enabled: !!query })

  let permissions = {}
  // L'utilisateur est un super admin
  if (userLoggedIn.is_admin) {
    permissions.isSuperAdmin = true
    permissions.canAddProject = true
  }
  if (projectUserRels && projectUserRels.length > 0) {
    projectUserRels.forEach((projectUserRel) => {
      // administrateur dans un projet
      if (projectUserRel.role === projectUserRelsService.ROLE_ADMINISTRATOR) {
        permissions.isAnyProjectAdministrator = true
        permissions.canAddProject = true
      }
      // assistant dans un projet
      if (projectUserRel.role === projectUserRelsService.ROLE_ASSISTANT) {
        permissions.isAnyProjectAssistant = true
      }
      // conducteur dans un projet
      if (projectUserRel.role === projectUserRelsService.ROLE_MANAGER) {
        permissions.isAnyProjectManager = true
      }
      // commercial dans un projet
      if (projectUserRel.role === projectUserRelsService.ROLE_COMMERCIAL) {
        permissions.isAnyProjectCommercial = true
      }
      // consultant dans un projet
      if (projectUserRel.role === projectUserRelsService.ROLE_CONSULTANT) {
        permissions.isAnyProjectConsultant = true
      }
      // professionnel dans un projet
      if (projectUserRel.role === projectUserRelsService.ROLE_WORKER) {
        permissions.isAnyProjectWorker = true
      }
      // client dans un projet
      if (projectUserRel.role === projectUserRelsService.ROLE_CUSTOMER) {
        permissions.isAnyProjectCustomer = true
      }
    })
  }

  if (Object.keys(permissions).length !== 0) {
    //console.log("usePermissions - permissions : ", permissions)
  }

  return {
    userLoggedIn,
    permissions,
    isError,
    isLoading,
    isFetching,
    refetch
  }
}

/**
 * Calculate permissions for loggedIn user on specified project
 * @param {object} options {id:number}
 * @returns object
 */
 export function useProjectPermissions (id, options = {}) {

  // Chargement des permissions globales de l'utilisateur
  const {
    userLoggedIn,
    permissions,
    isError: isErrorGlobal,
    isLoading: isLoadingGlobal,
    isFetching: isFetchingGlobal,
    refetch: refetchGlobal
  } = usePermissions()

  // Chargement du projet
  const {
    isLoading: isLoadingProject,
    isFetching: isFetchingProject,
    isError: isErrorProject,
    refetch: refetchProject,
    data: project
  } = useQuery(['projects', id], async () => {
    return projectsService.get(id).then(([item]) => item)
  }, { enabled: !!id })

  // Chargement des roles de l'utilisateur dans le projet
  const query = (id && userLoggedIn?.ID) ? {id_project: id, id_user: userLoggedIn.ID} : undefined
  const {
    isLoading: isLoadingRels,
    isFetching: isFetchingRels,
    isError: isErrorRels,
    refetch: refetchRels,
    data: projectUserRels
  } = useQuery(['project-user-rels', query], async () => {
    return projectUserRelsService.getAll(query).then(([items]) => items)
  }, { enabled: !!query })

  let projectPermissions = {}
  // super admin
  if (permissions?.isSuperAdmin) {
    projectPermissions.canUpdate = true
    projectPermissions.canSetState = true
    projectPermissions.canDelete = true
    projectPermissions.canViewUserRels = true
    projectPermissions.canViewMonitoring = true
    projectPermissions.canManageMonitoring = true
    projectPermissions.canManagePlannings = true
    // si le projet n'est pas terminé ou fermé
    if (project?.state !== projectsService.STATE_DONE && project?.state !== projectsService.STATE_CLOSED) {
      projectPermissions.canViewPlanningLong = true
      projectPermissions.canViewPlanningShort = true
      projectPermissions.canAddMedia = true
      projectPermissions.canAddDocument = true
      projectPermissions.canAddUserRel = true
    }
    // si le projet est terminé
    if (project?.state === projectsService.STATE_DONE) {
      projectPermissions.canViewSupportTickets = true
      projectPermissions.canAddSupportTicket = true
    }
  }
  if (projectUserRels && projectUserRels.length > 0) {
    projectUserRels.forEach((projectUserRel) => {
      // administrateur du projet
      if (projectUserRel.role === projectUserRelsService.ROLE_ADMINISTRATOR) {
        projectPermissions.isAdministrator = true
        projectPermissions.canUpdate = true
        projectPermissions.canSetState = true
        projectPermissions.canDelete = true
        projectPermissions.canViewUserRels = true
        projectPermissions.canViewMonitoring = true
        projectPermissions.canManageMonitoring = true
        projectPermissions.canManagePlannings = true
        // si le projet n'est pas terminé ou fermé
        if (project?.state !== projectsService.STATE_DONE && project?.state !== projectsService.STATE_CLOSED) {
          projectPermissions.canViewPlanningLong = true
          projectPermissions.canViewPlanningShort = true
          projectPermissions.canAddMedia = true
          projectPermissions.canAddDocument = true
          projectPermissions.canAddUserRel = true
        }
        // si le projet est terminé
        if (project?.state === projectsService.STATE_DONE) {
          projectPermissions.canViewSupportTickets = true
          projectPermissions.canAddSupportTicket = true
        }
      }
      // assitant du projet
      if (projectUserRel.role === projectUserRelsService.ROLE_ASSISTANT) {
        projectPermissions.isAssistant = true
        projectPermissions.canUpdate = true
        projectPermissions.canSetState = true
        projectPermissions.canViewUserRels = true
        projectPermissions.canViewMonitoring = true
        projectPermissions.canManageMonitoring = true
        projectPermissions.canManagePlannings = true
        // si le projet n'est pas terminé ou fermé
        if (project?.state !== projectsService.STATE_DONE && project?.state !== projectsService.STATE_CLOSED) {
          projectPermissions.canViewPlanningLong = true
          projectPermissions.canViewPlanningShort = true
          projectPermissions.canAddMedia = true
          projectPermissions.canAddDocument = true
          projectPermissions.canAddUserRel = true
        }
        // si le projet est terminé
        if (project?.state === projectsService.STATE_DONE) {
          projectPermissions.canViewSupportTickets = true
        }
      }
      // conducteur du projet
      if (projectUserRel.role === projectUserRelsService.ROLE_MANAGER) {
        projectPermissions.isManager = true
        projectPermissions.canViewUserRels = true
        projectPermissions.canViewMonitoring = true
        projectPermissions.canManageMonitoring = true
        projectPermissions.canManagePlannings = true
        // si le projet n'est pas terminé ou fermé
        if (project?.state !== projectsService.STATE_DONE && project?.state !== projectsService.STATE_CLOSED) {
          projectPermissions.canViewPlanningLong = true
          projectPermissions.canViewPlanningShort = true
          projectPermissions.canAddMedia = true
        }
        // si le projet est terminé
        if (project?.state === projectsService.STATE_DONE) {
          projectPermissions.canViewSupportTickets = true
          projectPermissions.canAddSupportTicket = true
        }
      }
      // commercial du projet
      if (projectUserRel.role === projectUserRelsService.ROLE_COMMERCIAL) {
        projectPermissions.isCommercial = true
        projectPermissions.canViewUserRels = true
        projectPermissions.canViewMonitoring = true
        // si le projet n'est pas terminé ou fermé
        if (project?.state !== projectsService.STATE_DONE && project?.state !== projectsService.STATE_CLOSED) {
          projectPermissions.canViewPlanningLong = true
          projectPermissions.canViewPlanningShort = true
          projectPermissions.canAddDocument = true
        }
        // si le projet est terminé
        if (project?.state === projectsService.STATE_DONE) {
          projectPermissions.canViewSupportTickets = true
        }
      }
      // consultant du projet
      if (projectUserRel.role === projectUserRelsService.ROLE_CONSULTANT) {
        projectPermissions.isConsultant = true
        projectPermissions.canViewUserRels = true
        projectPermissions.canViewMonitoring = true
        // si le projet n'est pas terminé ou fermé
        if (project?.state !== projectsService.STATE_DONE && project?.state !== projectsService.STATE_CLOSED) {
          projectPermissions.canViewPlanningLong = true
          projectPermissions.canViewPlanningShort = true
        }
        // si le projet est terminé
        if (project?.state === projectsService.STATE_DONE) {
          projectPermissions.canViewSupportTickets = true
        }
      }
      // professionnel du projet
      if (projectUserRel.role === projectUserRelsService.ROLE_WORKER) {
        projectPermissions.isWorker = true
        projectPermissions.canViewMonitoring = true
        // si le projet n'est pas terminé ou fermé
        if (project?.state !== projectsService.STATE_DONE && project?.state !== projectsService.STATE_CLOSED) {
          projectPermissions.canViewPlanningLong = true
          projectPermissions.canViewPlanningShort = true
        }
        // si le projet est terminé
        if (project?.state === projectsService.STATE_DONE) {
          projectPermissions.canViewSupportTickets = true
          projectPermissions.canAddSupportTicket = true
        }
      }
      // client du projet
      if (projectUserRel.role === projectUserRelsService.ROLE_CUSTOMER) {
        projectPermissions.isCustomer = true
        // si le projet n'est pas terminé ou fermé
        if (project?.state !== projectsService.STATE_DONE && project?.state !== projectsService.STATE_CLOSED) {
          projectPermissions.canViewPlanningLong = true
        }
        // si le projet est terminé
        if (project?.state === projectsService.STATE_DONE) {
          projectPermissions.canViewSupportTickets = true
          projectPermissions.canAddSupportTicket = true
        }
      }
    })
  }
  // Si l'utilisateur courant peut modifier le statut du projet, on étophe en fonction de l'état
  if (projectPermissions.canSetState === true && project) {
    // on ne peut valider le projet que s'il est initial
    if (project?.state === projectsService.STATE_INITIAL) {
      projectPermissions.canSetStateValidated = true
    }
    // on ne peut mettre en production le projet que s'il est validé
    if (project?.state === projectsService.STATE_VALIDATED) {
      projectPermissions.canSetStateProcessing = true
    }
    // on ne peut mettre en finaliser le projet que s'il est en production
    if (project?.state === projectsService.STATE_PROCESSING) {
      projectPermissions.canSetStateDone = true
    }
    // on ne peut fermer le projet que s'il est terminé
    if (project?.state === projectsService.STATE_DONE) {
      projectPermissions.canSetStateClosed = true
    }
  }

  if (Object.keys(projectPermissions).length !== 0) {
    //console.log("useProjectPermissions - projectPermissions : ", projectPermissions)
  }

  return {
    userLoggedIn,
    permissions,
    projectPermissions,
    project,
    isError: isErrorGlobal || isErrorProject || isErrorRels,
    isLoading: isLoadingGlobal || isLoadingProject || isLoadingRels,
    isFetching: isFetchingGlobal || isFetchingProject || isFetchingRels,
    refetch: () => {
      refetchGlobal && refetchGlobal()
      refetchProject && refetchProject()
      refetchRels && refetchRels()
    }
  }
}

/**
 * Calculate permissions for loggedIn user on specified document
 * @param {object} options {id:number}
 * @returns object
 */
export function useDocumentPermissions (id, options = {}) {

  // Chargement du document
  const {
    isLoading,
    isFetching,
    isError,
    refetch,
    data: document
  } = useQuery(['documents', id], async () => {
    return documentsService.get(id).then(([item]) => item)
  }, { enabled: !!id })

  // Chargement des permissions du projet du document
  const {
    userLoggedIn,
    permissions,
    projectPermissions,
    project,
    isLoading: isLoadingProject,
    isFetching: isFetchingProject,
    isError: isErrorProject,
    refetch: refetchProject
  } = useProjectPermissions(document?.id_project)

  let documentPermissions = {}
  // on se base sur les permissions générales déjà calculées
  if (permissions) {
    // super admin
    if (permissions.isSuperAdmin) {
      documentPermissions.canUpdate = true
      documentPermissions.canSetState = true
      documentPermissions.canDelete = true
      // si le document est en brouillon
      if (document?.state === documentsService.STATE_DRAFT) {
        documentPermissions.canAddAttachment = true
        documentPermissions.canUpdateAttachments = true
        documentPermissions.canDeleteAttachments = true
        documentPermissions.canAddScope = true
        documentPermissions.canUpdateScopes = true
        documentPermissions.canDeleteScopes = true
      }
    }
  }
  // on se base sur les permissions relatives au projet déjà calculées
  if (projectPermissions) {
    // administrateur du projet
    if (projectPermissions.isAdministrator) {
      documentPermissions.canUpdate = true
      documentPermissions.canSetState = true
      documentPermissions.canDelete = true
      // si le document est en brouillon
      if (document?.state === documentsService.STATE_DRAFT) {
        documentPermissions.canAddAttachment = true
        documentPermissions.canUpdateAttachments = true
        documentPermissions.canAddScope = true
        documentPermissions.canUpdateScopes = true
      }
    }
    // assitant du projet
    if (projectPermissions.isAssistant) {
      documentPermissions.canUpdate = true
      documentPermissions.canSetState = true
      // si le document est en brouillon
      if (document?.state === documentsService.STATE_DRAFT) {
        documentPermissions.canAddAttachment = true
        documentPermissions.canUpdateAttachments = true
        documentPermissions.canAddScope = true
        documentPermissions.canUpdateScopes = true
      }
    }
  }
  // le référent du document
  if (document?.id_user_referer === userLoggedIn?.ID) {
    documentPermissions.canUpdate = true
    documentPermissions.canSetState = true
    documentPermissions.canDelete = true
    // si le document est en brouillon
    if (document?.state === documentsService.STATE_DRAFT) {
      documentPermissions.canAddAttachment = true
      documentPermissions.canUpdateAttachments = true
      documentPermissions.canAddScope = true
      documentPermissions.canUpdateScopes = true
    }
  }
  // Si l'utilisateur courant peut modifier le document, on étophe en fonction de l'état
  if (documentPermissions.canSetState === true) {
    // on ne peut valider le document que si il a au moins une cible, au moins un fichier et qu'il est en brouillon
    if (document?.count_attachments > 0 && document?.count_scopes > 0 && document?.state === documentsService.STATE_DRAFT) {
      documentPermissions.canSetStateValidated = true
    }
  }

  if (Object.keys(documentPermissions).length !== 0) {
    //console.log("useDocumentPermissions - documentPermissions : ", documentPermissions)
  }

  return {
    userLoggedIn,
    permissions,
    projectPermissions,
    project,
    documentPermissions,
    document,
    isLoading: isLoadingProject || isLoading,
    isFetching: isFetchingProject || isFetching,
    isError: isErrorProject || isError,
    refetch: () => {
      refetchProject && refetchProject()
      refetch && refetch()
    }
  }
}

/**
 * Calculate permissions for loggedIn user on specified project user rel
 * @param {object} options {id:number}
 * @returns object
 */
export function useProjectUserRelPermissions (id, options = {}) {

  // Chargement de la relation
  const {
    isLoading,
    isFetching,
    isError,
    refetch,
    data: projectUserRel
  } = useQuery(['project-user-rels', id], async () => {
    return projectUserRelsService.get(id).then(([item]) => item)
  }, { enabled: !!id })

  // Chargement des permissions du projet de la relation
  const {
    userLoggedIn,
    permissions,
    projectPermissions,
    project,
    isLoading: isLoadingProject,
    isFetching: isFetchingProject,
    isError: isErrorProject,
    refetch: refetchProject
  } = useProjectPermissions(projectUserRel?.id_project)

  let projectUserRelPermissions = {}
  // on se base sur les permissions globales
  if (permissions) {
    // super admin
    if (permissions.isSuperAdmin) {
      projectUserRelPermissions.canDelete = true
    }
  }
  // on se base sur les permissions relatives au projet
  if (projectPermissions) {
    // administrateur du projet
    if (projectPermissions.isAdministrator) {
      projectUserRelPermissions.canDelete = true
    }
  }
  // le référent de la relation peut la supprimer
  if (projectUserRel?.id_user_referer === userLoggedIn?.ID) {
    projectUserRelPermissions.canDelete = true
  }

  if (Object.keys(projectUserRelPermissions).length !== 0) {
    //console.log("useProjectUserRelPermissions - projectUserRelPermissions : ", projectUserRelPermissions)
  }

  return {
    userLoggedIn,
    permissions,
    projectPermissions,
    project,
    projectUserRelPermissions,
    projectUserRel,
    isLoading: isLoadingProject || isLoading,
    isFetching: isFetchingProject || isFetching,
    isError: isErrorProject || isError,
    refetch: () => {
      refetchProject && refetchProject()
      refetch && refetch()
    }
  }
}

/**
 * Calculate permissions for loggedIn user on specified media
 * @param {object} options {id:number}
 * @returns object
 */
export function useProjectMediaPermissions (id, options = {}) {

  // Chargement du media
  const {
    isLoading,
    isFetching,
    isError,
    refetch,
    data: media
  } = useQuery(['medias', id], async () => {
    return mediasService.get(id).then(([item]) => item)
  }, { enabled: !!id })

  // Chargement des permissions du projet du media
  const {
    userLoggedIn,
    permissions,
    projectPermissions,
    project,
    isLoading: isLoadingProject,
    isFetching: isFetchingProject,
    isError: isErrorProject,
    refetch: refetchProject
  } = useProjectPermissions(media?.id_project)

  // On vérifie que le média est bien rattaché à un projet
  if (media && !media.id_project) {
    console.warn("Le média n'est pas rattaché à un projet, vous ne devez pas pas utiliser useProjectMediaPermissions")
  }

  let mediaPermissions = {}
  // on se base sur les permissions globales
  if (permissions) {
    // super admin
    if (permissions.isSuperAdmin) {
      mediaPermissions.canUpdate = true
      mediaPermissions.canDelete = true
    }
  }
  // on se base sur les permissions relatives au projet
  if (projectPermissions) {
    // administrateur du projet
    if (projectPermissions.isAdministrator) {
      mediaPermissions.canUpdate = true
      mediaPermissions.canDelete = true
    }
    // assistant du projet
    if (projectPermissions.isAssistant) {
      mediaPermissions.canUpdate = true
    }
  }
  // le référent du media peut le modifier/supprimer
  if (userLoggedIn && media?.id_user_referer === userLoggedIn?.ID) {
    mediaPermissions.canUpdate = true
    mediaPermissions.canDelete = true
  }

  if (Object.keys(mediaPermissions).length !== 0) {
    //console.log("useProjectMediaPermissions - mediaPermissions : ", mediaPermissions)
  }

  return {
    userLoggedIn,
    permissions,
    projectPermissions,
    project,
    mediaPermissions,
    media,
    isLoading: isLoadingProject || isLoading,
    isFetching: isFetchingProject || isFetching,
    isError: isErrorProject || isError,
    refetch: () => {
      refetchProject && refetchProject()
      refetch && refetch()
    }
  }
}

/**
 * Calculate permissions for loggedIn user on specified user
 * @param {object} options {id:number}
 * @returns object
 */
 export function useUserPermissions (id, options = {}) {

  // Chargement des permissions globales de l'utilisateur
  const {
    userLoggedIn,
    permissions,
    isError: isErrorGlobal,
    isLoading: isLoadingGlobal,
    isFetching: isFetchingGlobal,
    refetch: refetchGlobal
  } = usePermissions()

  // Chargement de l'utilisateur
  const {
    isLoading,
    isFetching,
    isError,
    refetch,
    data: user
  } = useQuery(['users', id], async () => {
    return usersService.get(id).then(([item]) => item)
  }, { enabled: !!id })

  let userPermissions = {}
  // super admin
  if (permissions?.isSuperAdmin) {
    userPermissions.canUpdate = true
    userPermissions.canDelete = true
  }
  // l'utilisateur lui-même
  // IMPORTANT : on test directement l'id passé en paramètre, on n'attend pas user.ID
  // car si l'utilisateur a un souci de token il ne peut pas récupérer son compte dans l'API et donc
  //  il ne pourrait plus se déconnecté de l'app.
  if (id === userLoggedIn?.ID) {
    userPermissions.canUpdate = true
    userPermissions.isHimself = true
  }

  if (Object.keys(userPermissions).length !== 0) {
    //console.log("useUserPermissions - userPermissions : ", userPermissions)
  }

  return {
    userLoggedIn,
    permissions,
    userPermissions,
    user,
    isError: isErrorGlobal || isError,
    isLoading: isLoadingGlobal || isLoading,
    isFetching: isFetchingGlobal || isFetching,
    refetch: () => {
      refetchGlobal && refetchGlobal()
      refetch && refetch()
    }
  }
}

/**
 * Calculate permissions for loggedIn user on specified support ticket
 * @param {object} options {id:number}
 * @returns object
 */
 export function useSupportTicketPermissions (id, options = {}) {

  // Chargement du ticket
  const {
    isLoading,
    isFetching,
    isError,
    refetch,
    data: supportTicket
  } = useQuery(['support-tickets', id], async () => {
    return supportTicketsService.get(id).then(([item]) => item)
  }, { enabled: !!id })

  // Chargement des permissions du projet du ticket
  const {
    userLoggedIn,
    permissions,
    projectPermissions,
    project,
    isLoading: isLoadingProject,
    isFetching: isFetchingProject,
    isError: isErrorProject,
    refetch: refetchProject
  } = useProjectPermissions(supportTicket?.id_project)

  // On vérifie que le média est bien rattaché à un projet
  if (supportTicket && !supportTicket.id_project) {
    console.warn("Le ticket n'est pas rattaché à un projet, vous ne devez pas pas utiliser useSupportTicketPermissions")
  }

  let supportTicketPermissions = {}
  let canManageState = false
  let canUpdate = false
  let canAddMessage = false
  // on se base sur les permissions globales
  if (permissions) {
    // super admin
    if (permissions.isSuperAdmin) {
      supportTicketPermissions.canDelete = true
      supportTicketPermissions.canSetTarget = true
      canUpdate = true
      canAddMessage = true
      canManageState = true
    }
  }
  // on se base sur les permissions relatives au projet
  if (projectPermissions) {
    // administrateur du projet
    if (projectPermissions.isAdministrator) {
      supportTicketPermissions.canDelete = true
      supportTicketPermissions.canSetTarget = true
      canUpdate = true
      canAddMessage = true
      canManageState = true
    }
    // conducteur du projet
    if (projectPermissions.isManager) {
      canUpdate = true
      canAddMessage = true
    }
  }
  // le référent du ticket peut le modifier et ajouter un message
  if (supportTicket?.id_user_referer === userLoggedIn?.ID) {
    canUpdate = true
    canAddMessage = true
  }
  // la cible du ticket peut ajouter un message
  if (supportTicket?.id_user === userLoggedIn?.ID) {
    canAddMessage = true
  }
  // si l'utilisateur peur changer le statut du ticket, on affine en fonction du statut actuel
  if (canManageState) {
    if (supportTicket?.state === supportTicketsService.STATE_OPENED) {
      supportTicketPermissions.canSetStateClosed = true
    }
    if (supportTicket?.state === supportTicketsService.STATE_CLOSED) {
      supportTicketPermissions.canSetStateOpened = true
    }
  }
  // si l'utilisateur peur ajouter un message au ticket, on affine en fonction du statut actuel
  if (canAddMessage) {
    if (supportTicket?.state === supportTicketsService.STATE_OPENED) {
      supportTicketPermissions.canAddMessage = true
    }
  }
  // si l'utilisateur peur modifier le ticket, on affine en fonction du statut actuel
  if (canUpdate) {
    if (supportTicket?.state === supportTicketsService.STATE_OPENED) {
      supportTicketPermissions.canUpdate = true
    }
  }

  return {
    userLoggedIn,
    permissions,
    projectPermissions,
    project,
    supportTicketPermissions,
    supportTicket,
    isLoading: isLoadingProject || isLoading,
    isFetching: isFetchingProject || isFetching,
    isError: isErrorProject || isError,
    refetch: () => {
      refetchProject && refetchProject()
      refetch && refetch()
    }
  }
}

/**
 * Calculate permissions for loggedIn user on specified support ticket message
 * @param {object} options {id:number}
 * @returns object
 */
 export function useSupportTicketMessagePermissions (idSupporTicket, id, options = {}) {

  // Chargement du message du ticket
  const {
    isLoading,
    isFetching,
    isError,
    refetch,
    data: supportTicketMessage
  } = useQuery(['support-tickets-' + idSupporTicket + '-messages', id], async () => {
    console.log("useSupportTicketMessagePermissions - useQuery - queryKeys : ", ['support-tickets-' + idSupporTicket + '-messages', id])
    return supportTicketMessagesService.get(idSupporTicket, id).then(([item]) => item)
  }, { enabled: !!idSupporTicket && !!id })

  // Chargement des permissions du projet
  const {
    userLoggedIn,
    permissions,
    projectPermissions,
    project,
    isLoading: isLoadingProject,
    isFetching: isFetchingProject,
    isError: isErrorProject,
    refetch: refetchProject
  } = useProjectPermissions(supportTicketMessage?.id_project)

  // On vérifie que le message est bien rattaché à un projet
  if (supportTicketMessage && !supportTicketMessage.id_project) {
    console.warn("Le message du ticket n'est pas rattaché à un projet, vous ne devez pas pas utiliser useSupportTicketMessagePermissions")
  }

  let supportTicketMessagePermissions = {}
  // on se base sur les permissions globales
  if (permissions) {
    // super admin
    if (permissions.isSuperAdmin) {
      supportTicketMessagePermissions.canUpdate = true
      supportTicketMessagePermissions.canDelete = true
    }
  }
  // on se base sur les permissions du projet
  if (projectPermissions) {
    // administrateur du projet
    if (projectPermissions.isAdministrator) {
      supportTicketMessagePermissions.canUpdate = true
      supportTicketMessagePermissions.canDelete = true
    }
  }
  // le référent du message du message du ticket (c'est l'auteur)
  if (supportTicketMessage?.id_user_referer === userLoggedIn?.ID) {
    supportTicketMessagePermissions.canUpdate = true
    supportTicketMessagePermissions.canDelete = true
  }

  return {
    userLoggedIn,
    permissions,
    supportTicketMessagePermissions,
    supportTicketMessage,
    projectPermissions,
    project,
    isLoading: isLoadingProject || isLoading,
    isFetching: isFetchingProject || isFetching,
    isError: isErrorProject || isError,
    refetch: () => {
      refetchProject && refetchProject()
      refetch && refetch()
    }
  }
}

/**
 * Download attachment from API
 * @param {object} options {onDownload:func, onStarted:func, onCompleted:func, onError:func}
 * @returns object
 */
 export function useDownload (options = {}) {

  const ref = useRef()
  const [url, setFileUrl] = useState()
  const [isDownloading, setIsDownloading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)

  const download = async () => {
    setIsError(false)
    setIsDownloading(true)
    setIsDownloaded(false)
    try {
      options.onStarted && options.onStarted()
      options.onDownload && options.onDownload().then((response) => {

        const blob = new Blob([response.data], {type: response.headers['content-type']})
        const fileURL = URL.createObjectURL(blob)
        setFileUrl(fileURL)

        // On attend un court instant afin que React ai rendu/mis à jour l'élément pointé par ref
        setTimeout(() => {
          ref.current?.click()
          setIsDownloading(false)
          setIsDownloaded(true)
          options.onCompleted && options.onCompleted(response)
        }, 500)
      })      
    } catch (error) {
      setIsDownloading(false)
      setIsError(true)
      options.onError && options.onError(error)
    }
  }
  return {download, isDownloaded, ref, url, isDownloading, isError}
}